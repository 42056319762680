import React, { useState, useEffect, useLayoutEffect } from 'react';
import { SessionContext } from './DataContext';
import axios from "axios";
import config from '../components/configuration/config';

export const SessionState = ({ children }) => {

  const [dataSession, setDataSession] = useState([])
  const [isEvent, setEvent] = useState(config.currentEventID) // ID del evento actual está guardado en config.js
  const [isLang, setLang] = useState('es')
  const [status, setStatus] = useState([]);
  useEffect(() => {
    const loadOurEvents = async () => {
      try {
        const responseJSON = await axios.get(`https://apievt.lacnic.net/wp-json/wp/v2/sessions?per_page=20&lang=${isLang}&our_events=${isEvent}`);
        setDataSession(responseJSON.data);
        if (responseJSON.data.status) {
          setStatus(responseJSON.data.status);
        }
      } catch (error) {
        console.error('Error al cargar las sesiones:', error);
        // Manejar el error de alguna manera (por ejemplo, mostrar un mensaje de error)
      }
    };
  
    loadOurEvents();
  }, [isLang, isEvent]);
   
  const session_slug = `${dataSession.map((item) => item.slug)}`
    /*const session =  session_slug.split(',')[0] ; console.log(session_slug.split(',')[0]) 
  
   session_slug.split(',')[1] :
   */ 
 
   const handleLang = (value) => {
    setLang(value)
  }
  const handleEvent = (value) => {
    setEvent(value)
  }

  const [agendaData, setAgendaData] = useState([]);
  const [current_session, setCurrentSession] = useState(101);
   

  useEffect(() => {
    const loadOurEvents = async () => {
      try {
        if (typeof current_session === 'string' && current_session.trim()) {
          const { data } = await axios.get(`https://apievt.lacnic.net/wp-json/wp/v2/agenda?per_page=100&agenda_sessions=${current_session}&our_events=${isEvent}`);
          setAgendaData(data);
        }
      } catch (error) {
        console.error('Error al cargar los eventos:', error);
        // Manejar el error de alguna manera (por ejemplo, mostrar un mensaje de error)
      }
    };
  
    loadOurEvents();
  }, [current_session, isEvent]);
  

  const handleCurrentSession = (value) => {
    setCurrentSession(value)
  }

  const [presentations, setPresentations] = useState([])
  const [presentationLoading, setPresentationLoading] = useState([])
  useEffect(() => {
    async function loadPresentation() {
      try {
        const presentation = [];
        const response = await fetch('https://apievt.lacnic.net/wp-json/wp/v2/agenda');
        if (!response.ok) {
          throw new Error(`Failed to fetch sponsors: ${response.status} ${response.statusText}`);
        }
        const totalPages = response.headers.get('X-WP-TotalPages');
        for (let i = 1; i <= totalPages; i++) {
          const pageResponse = await fetch(`https://apievt.lacnic.net/wp-json/wp/v2/agenda?page=${i}`);
          if (!pageResponse.ok) {
            throw new Error(`Failed to fetch sponsors for page ${i}: ${pageResponse.status} ${pageResponse.statusText}`);
          }
          const pagePresentation = await pageResponse.json();
          presentation.push(...pagePresentation);
        }
        setPresentations(presentation);
        setPresentationLoading(true);
      } catch (error) {
        console.error(error);
      }
    }
    loadPresentation();
  }, []);

  const [agendaLACNIC40, setAgendaLACNIC40] = useState([])
  const [agenda40Loading, setAgenda40Loading] = useState([])
  useEffect(() => {
    async function loadAgendaLACNIC40() {
      try {
        const agendaLACNIC40 = [];
        const response = await fetch(`https://apievt.lacnic.net/wp-json/wp/v2/agenda?our_events=${isEvent}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch sponsors: ${response.status} ${response.statusText}`);
        }
        const totalPages = response.headers.get('X-WP-TotalPages');
        for (let i = 1; i <= totalPages; i++) {
          const pageResponse = await fetch(`https://apievt.lacnic.net/wp-json/wp/v2/agenda?our_events=${isEvent}&page=${i}`);
          if (!pageResponse.ok) {
            throw new Error(`Failed to fetch sponsors for page ${i}: ${pageResponse.status} ${pageResponse.statusText}`);
          }
          const pagePresentation = await pageResponse.json();
          agendaLACNIC40.push(...pagePresentation);
        }
        setAgendaLACNIC40(agendaLACNIC40);
        setAgenda40Loading(true);
      } catch (error) {
        console.error(error);
      }
    }
    loadAgendaLACNIC40();
  }, [isEvent]);

  /* Manejador de daypicker en la grilla de la agenda */
  const [selectedDateStr, setSelectedDay] = useState(null);  
  const handleDaySelection = (day) => {
    setSelectedDay(day);
  };

  /* Adaptar al Layout */
  const [tableStyles, setTableStyles] = useState('StandardTable');

  useLayoutEffect(() => {
      const actualizarTabla = () => {
          const anchoPantalla = window.innerWidth;
          if (anchoPantalla <= 768) {
              setTableStyles('ResponsiveTable');
          } else {
              setTableStyles('StandardTable');
          }
      };
      actualizarTabla();
      window.addEventListener('resize', actualizarTabla);
      return () => window.removeEventListener('resize', actualizarTabla);
  }, []);
  
  return (
    <SessionContext.Provider value={{
      dataSession,
      handleLang,
      handleEvent,
      status,
      session_slug,
      setCurrentSession,
      agendaData,
      handleCurrentSession,
      presentations,
      presentationLoading,
      agendaLACNIC40,
      agenda40Loading,
      handleDaySelection,
      selectedDateStr,
      tableStyles
    }}>
      {children}
    </SessionContext.Provider>
  );
}

