import React, { useContext, useEffect, useState } from 'react' 
import './presentations.css'
import Ellipsis from '../../../../../components/animations/loadings/Ellipsis';
import TimeCheckbox from '../../table-functionalities/TimeCheckbox'; 
import PresentationRow from './presentation-row/PresentationRow';
import { SessionContext } from '../../../../../context/DataContext';
import DayPicker from '../../table-functionalities/DayPicker';
import UseLang from '../../../../../components/hook/UseLang';

const Presentations = props => {
    const { current_session } = props;
    const { agendaData, handleCurrentSession } = useContext(SessionContext);
    useEffect(() => {
        handleCurrentSession(current_session);
    }, [current_session, handleCurrentSession]);
     
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);   
    const isCurrentSession160 = current_session === '160';
    return (
        <>{agendaData[0] && <>
            <>{!loading
                ?
                <>
                    { isCurrentSession160 ? 
                    <article className="registration_information">
                        <div> 
                            <div> 
                                <UseLang es><p>Mas info y registro obligatorio aqu&iacute;:&nbsp;<a  href="https://www.first.org/events/symposium/fortaleza2023/" target="_blank" rel="noopener noreferrer" >https://www.first.org/events/symposium/fortaleza2023/</a></p></UseLang>
                                <UseLang en><p>More info and registration (mandatory):&nbsp;<a  href="https://www.first.org/events/symposium/fortaleza2023/" target="_blank" rel="noopener noreferrer"  >https://www.first.org/events/symposium/fortaleza2023/</a></p></UseLang>
                                <UseLang pt_br><p>Mais informa&ccedil;&otilde;es e inscri&ccedil;&atilde;o obrigat&oacute;ria aqui: <a  href="https://www.first.org/events/symposium/fortaleza2023/" target="_blank" rel="noopener noreferrer" >https://www.first.org/events/symposium/fortaleza2023/</a></p></UseLang>
                            </div>
                        </div>
                    </article>: undefined} 
                    <TimeCheckbox />
                    <DayPicker agendaData={agendaData} /> 
                    <PresentationRow agendaData={agendaData}/> 
                </>
                :
                <article>
                    <div>
                        <Ellipsis />
                    </div>
                </article>
            }</>
        </>}
        </>
    )
}

export default Presentations
