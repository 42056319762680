import React, { useContext, useState } from 'react';
import './main-nav-menu.css'
import useMenuContext from '../../../hook/UseMenu';
import { NavLink, Link } from 'react-router-dom';
import slugify from 'react-slugify';
import { LangContext } from '../../../../context/DataContext';
import Stand from '../stand/Stand'

const MainNavMenu = props => {
    const { isMenu, loading } = useMenuContext();
    const { myLang } = useContext(LangContext)

    const [openMenu, setOpenMenu] = useState(null);

    const handleMouseEnter = (itemId) => {
        setOpenMenu(itemId);
    };

    const handleMouseLeave = () => {
        setOpenMenu(null);
    }; 
    return (
        <>
            <nav className='mainNavMenu main-nav-menu'>
                <ul className='mainNavMenu_items'>
                    {!loading && isMenu?.items && Array.isArray(isMenu.items) ? (
                        isMenu.items.map((myItem) => {
                            return (
                                <li
                                    key={myItem.ID}
                                    onMouseEnter={() => handleMouseEnter(myItem.ID)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <button className='menu_item'>{myItem.title}</button>
                                    <ul className={`mainNavMenu_dropdownBox ${openMenu === myItem.ID && myItem.child_items ? 'open-dropdown' : ''}`}>
                                        {myItem.child_items && Array.isArray(myItem.child_items) ? (
                                            myItem.child_items.map((childItem) => (
                                                <React.Fragment key={childItem.ID}>
                                                    {/* Enlaces internos */}
                                                    {childItem.target === 'self' &&
                                                        <li>
                                                            <NavLink className='mainNavMenu_dropdownBox_link' to={`/${myLang}/${slugify(myItem.title)}/${childItem?.acf?.slug}`}>{childItem.title}</NavLink>
                                                        </li>}
                                                    {/* Enlaces externos */}
                                                    {childItem.target === 'blank' &&
                                                        <li>
                                                            <a className='mainNavMenu_dropdownBox_link' href={`${childItem?.acf?.url}`} target='_blank' rel="noreferrer">{childItem.title}</a>
                                                        </li>}
                                                    {/* Enlaces externos */}
                                                    {childItem.target === 'param' &&
                                                        <li> 
                                                            <Link className='mainNavMenu_dropdownBox_link' to={`${childItem?.acf?.url}`}>{childItem.title}</Link>
                                                        </li>}
                                                </React.Fragment>
                                            ))
                                        ) : null}
                                        {// Agrega elementos personalizados aquí para el menú 
                                            myItem.title === 'myItem.title'/* Agregar aquí el nombre del botón */ ? (
                                                <div>
                                                    <p>Contenido personalizado 1</p>
                                                    <p>Contenido personalizado 2</p>
                                                </div>
                                            ) : null}
                                             
                                    </ul>
                                </li>
                            );
                        })
                    ) : null}
                    <Stand />
                </ul>
            </nav>
        </>
    )
}

export default MainNavMenu
