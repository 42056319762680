import React, { useContext,  useState } from 'react';
import { PostContext } from '../../../context/DataContext';
import Main from '../../../components/interface/main/Main';
import Footer from '../../../components/navegation/footer/Footer';
import Header from '../../../components/navegation/header/Header'; 
import UsePost from '../../../components/hook/UsePost';
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import UseLangLocation from '../../../components/hook/UseLangLocation'; 
import UseHTML from '../../../components/hook/UseHTML';
import './fellowship-draw.css'

const FellowshipDraw = props => { 
    const {  handleApiURL, handleID, handleLoading, data } = useContext(PostContext)
    const [loading, setLoading] = useState(true); 
    console.log(data)
    return ( 
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/participate' handleLoading={handleLoading} handleID={handleID} id='2977' >
                <Header title={data?.title?.rendered} url_es='/es/como-participar/sorteo-de-becas-para-pequenos-isp-asociados-de-lacnic' url_en={'/en/how-to-participate/draw-to-win-a-fellowship-for-small-isps'}  loading={loading} {...props} />
                <Main className='container fellowship_draw' status={data?.status} loading={loading}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={data?.title?.rendered}>
                            <article>
                                <div> 
                                    <h1><UseHTML html={data?.title?.rendered} /></h1>
                                    <UseHTML html={data?.content?.rendered} />
                                </div>
                            </article>
                        </UseTimeOut>
                    </section>   
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation> 
    )
}

export default FellowshipDraw
