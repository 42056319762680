import React, { useContext } from 'react';
import { LangContext } from '../../../../context/DataContext';

const SubscriptionForm = () => {
    const { myLang } = useContext(LangContext)

    const Form = () => { 
        if(myLang === 'es') {
        return (
            <form
            className="js-cm-form"
            id="subForm"
            action="https://www.createsend.com/t/subscribeerror?description="
            method="post"
            data-id="5B5E7037DA78A748374AD499497E309E515360277F803A80C9FEC65A7074114AD617953D3055CAB8AEF9C4B57A4E525D00BF17E65B5CCCDD49903ED4CC27B778"
        >
            <div size="base" className="sc-jzJRlG bMslyb">
                <div size="small" className="sc-jzJRlG liOVdz">
                    <div>
                        <label size="0.875rem" color="#5d5d65" className="sc-gzVnrw dEVaGV">
                            Name <span className="sc-dnqmqq iFTUZ">*</span>
                        </label>
                        <input aria-label="Name" id="fieldName" maxLength="200" name="cm-name" placeholder="Nombre" required className="sc-iwsKbI iMsgpL" />
                    </div>
                </div>
                <div size="small" className="sc-jzJRlG liOVdz">
                    <div>
                        <label size="0.875rem" color="#5d5d65" className="sc-gzVnrw dEVaGV">
                            Apellido <span className="sc-dnqmqq iFTUZ">*</span>
                        </label>
                        <input aria-label="Apellido" id="fielddihdlrik" maxLength="200" name="cm-f-dihdlrik" required className="sc-iwsKbI iMsgpL" />
                    </div>
                </div>
                <div size="small" className="sc-jzJRlG liOVdz">
                    <div>
                        <label size="0.875rem" color="#5d5d65" className="sc-gzVnrw dEVaGV">
                            Email <span className="sc-dnqmqq iFTUZ">*</span>
                        </label>
                        <input
                            autoComplete="Email"
                            aria-label="Email"
                            id="fieldEmail"
                            maxLength="200"
                            name="cm-pujurt-pujurt"
                            placeholder="Dirección de email"
                            required
                            type="email"
                            className="js-cm-email-input qa-input-email sc-iwsKbI iMsgpL"
                        />
                    </div>
                </div>
                <div size="small" className="sc-jzJRlG liOVdz">
                    <div>
                        <label size="0.875rem" color="#5d5d65" className="sc-gzVnrw dEVaGV">
                            Si desea recibir las notas en su móvil, ingrese aquí su número
                        </label>
                        <input aria-label="Si desea recibir las notas en su móvil, ingrese aquí su número" id="fielddikdydut" maxLength="200" name="cm-f-dikdydut" className="sc-iwsKbI iMsgpL" />
                    </div>
                </div>
                <div size="base" className="sc-jzJRlG bMslyb">
                    <div>
                        <p>
                            <a size="16px" color="#19a9e5" href="https://www.lacnic.net/2923/1/lacnic/politica-de-privacidad" rel="noreferrer" target="_blank" className="sc-hMqMXs drTxYm">
                                Política de privacidad
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <button size="1rem" color="#fff" type="submit" className="js-cm-submit-button sc-iAyFgw jLHoXa">
                Suscribirse
            </button>
        </form>
        )} if (myLang === 'en'){
            return(<form
                className="js-cm-form"
                id="subForm"
                action="https://www.createsend.com/t/subscribeerror?description="
                method="post"
                data-id="5B5E7037DA78A748374AD499497E309ECDC8859430426C759679EA92DE5A4EAC234E067CE0E7131B5F87776D14718239A516B991F8B454C6EFD8632C804DF4AD"
              >
                <div size="base" className="sc-jzJRlG bMslyb">
                  <div size="small" className="sc-jzJRlG liOVdz">
                    <div>
                      <label size="0.875rem" color="#5d5d65" className="sc-gzVnrw dEVaGV">
                        Name <span className="sc-dnqmqq iFTUZ">*</span>
                      </label>
                      <input aria-label="Name" id="fieldName" maxLength="200" name="cm-name" required="" className="sc-iwsKbI iMsgpL" />
                    </div>
                  </div>
                  <div size="small" className="sc-jzJRlG liOVdz">
                    <div>
                      <label size="0.875rem" color="#5d5d65" className="sc-gzVnrw dEVaGV">
                        Surname <span className="sc-dnqmqq iFTUZ">*</span>
                      </label>
                      <input aria-label="Surname" id="fielddihdlyjh" maxLength="200" name="cm-f-dihdlyjh" required="" className="sc-iwsKbI iMsgpL" />
                    </div>
                  </div>
                  <div size="small" className="sc-jzJRlG liOVdz">
                    <div>
                      <label size="0.875rem" color="#5d5d65" className="sc-gzVnrw dEVaGV">
                        Email <span className="sc-dnqmqq iFTUZ">*</span>
                      </label>
                      <input
                        autoComplete="Email"
                        aria-label="Email"
                        id="fieldEmail"
                        maxLength="200"
                        name="cm-iddtkti-iddtkti"
                        required=""
                        type="email"
                        className="js-cm-email-input qa-input-email sc-iwsKbI iMsgpL"
                      />
                    </div>
                  </div>
                  <div size="small" className="sc-jzJRlG liOVdz">
                    <div>
                      <label size="0.875rem" color="#5d5d65" className="sc-gzVnrw dEVaGV">
                        If you'd like to receive the articles on your phone, enter your mobile number here
                      </label>
                      <input
                        aria-label="If you'd like to receive the articles on your phone, enter your mobile number here"
                        id="fielddikdyhlh"
                        maxLength="200"
                        name="cm-f-dikdyhlh"
                        type="number"
                        className="sc-iwsKbI iMsgpL"
                      />
                    </div>
                  </div>
                  <div size="base" className="sc-jzJRlG bMslyb">
                    <div>
                      <p>
                        <a size="16px" color="#19a9e5" href="https://www.lacnic.net/2924/2/lacnic/privacy-policy" rel="noreferrer" target="_blank" className="sc-hMqMXs drTxYm">
                          Privacy policy
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <button size="1rem" color="#fff" type="submit" className="js-cm-submit-button sc-iAyFgw jLHoXa">
                  Subscribe
                </button>
              </form>)
        } else if (myLang === 'pt-br'){
            return(
                <form
                className="js-cm-form"
                id="subForm"
                action="https://www.createsend.com/t/subscribeerror?description="
                method="post"
                data-id="5B5E7037DA78A748374AD499497E309E7DAB3B10BB30D1ECB6CA7B46AFFBD689737865413884CB153C7183F2290ABD5A5B497EDFF50BD987BCEEA2B83BCCF874"
            >
                <div size="base" className="sc-jzJRlG bMslyb">
                    <div size="small" className="sc-jzJRlG liOVdz">
                        <div>
                            <label size="0.875rem" color="#5d5d65" className="sc-gzVnrw dEVaGV">
                                Nome <span className="sc-dnqmqq iFTUZ">*</span>
                            </label>
                            <input
                                className="sc-iwsKbI iMsgpL"
                                aria-label="Nome"
                                id="fieldName"
                                maxLength="200"
                                name="cm-name"
                                placeholder="Nome"
                                required=""
                            />
                        </div>
                    </div>
                    <div size="small" className="sc-jzJRlG liOVdz">
                        <div>
                            <label size="0.875rem" color="#5d5d65" className="sc-gzVnrw dEVaGV">
                                Apellido <span className="sc-dnqmqq iFTUZ">*</span>
                            </label>
                            <input
                                className="sc-iwsKbI iMsgpL"
                                aria-label="Sobrenome"
                                id="fielddikdyho"
                                maxLength="200"
                                name="cm-f-dikdyho"
                                required=""
                            />
                        </div>
                    </div>
                    <div size="small" className="sc-jzJRlG liOVdz">
                        <div>
                            <label size="0.875rem" color="#5d5d65" className="sc-gzVnrw dEVaGV">
                                Endereço de Email <span className="sc-dnqmqq iFTUZ">*</span>
                            </label>
                            <input
                                autoComplete="Email"
                                aria-label="Endereço de Email"
                                className="js-cm-email-input qa-input-email sc-iwsKbI iMsgpL"
                                id="fieldEmail"
                                maxLength="200"
                                name="cm-btkhhlr-btkhhlr"
                                placeholder="Endereço de Email"
                                required=""
                                type="email"
                            />
                        </div>
                    </div>
                    <div size="small" className="sc-jzJRlG liOVdz">
                        <div>
                            <label size="0.875rem" color="#5d5d65" className="sc-gzVnrw dEVaGV">
                                Se você quiser receber as notas no seu celular, insira o seu número aqui
                            </label>
                            <input
                                aria-label="Se você quiser receber as notas no seu celular, insira o seu número aqui"
                                id="fielddikdyhp"
                                maxLength="200"
                                name="cm-f-dikdyhp"
                                type="number"
                                className="sc-iwsKbI iMsgpL"
                            />
                        </div>
                    </div>
                    <div size="base" className="sc-jzJRlG bMslyb">
                        <div>
                            <p>
                                <a
                                    size="16px"
                                    color="#19a9e5"
                                    href="https://www.lacnic.net/2925/3/lacnic/politica-de-privacidade"
                                    rel="noreferrer"
                                    target="_blank"
                                    className="sc-hMqMXs drTxYm"
                                >
                                    Política de privacidade
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <button size="1rem" color="#fff" type="submit" className="js-cm-submit-button sc-iAyFgw jLHoXa">
                    Inscrever-se
                </button>
            </form>
                )
        }
    }

    return (
        <div>
            <div className="l-center-container">
                <div className="sc-dxgOiQ gDVPix"></div>
                <div>
                    <div className="sc-bdVaJa iIDDUy">
                        <div>
                            <div>
                                <div spacing="base" className="sc-kEYyzF bjObOo"></div>
                            </div>
                            {Form()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionForm;

 