import React from 'react';
import CountDown from '../../../components/interface/count-down/CountDown';
import './main-image.css';
import UseHTML from '../../../components/hook/UseHTML';
/* import { Link } from 'react-router-dom';
import UseLang from '../../../components/hook/UseLang'; */

const MainImage = props => {
    const { featured_content, featured_image } = props;

    const bannerStyles = {
        'background': `url(${featured_image}) 0% 40% / cover no-repeat`,
    }

    const fechaActual = new Date();
    // Definir la fecha objetivo del evento tiene que ser dos días antes del comienzo del evento
    const fechaObjetivo = new Date(2024, 4, 6); // Nota: Los meses en JavaScript van de 0 a 11, por lo que mayo es 4
  
    // Verificar si la fecha actual es igual o pasó el día objetivo
    const esFechaObjetivo = fechaActual.getDate() >= fechaObjetivo.getDate();  
 
    return (
        <div className="main_image" style={bannerStyles}>
            <div className="main_image_backdrop_filter">
                <div>
                    {!esFechaObjetivo ? <CountDown /> :
                    <div className='homepage_header'> 
                        <UseHTML html={featured_content}/> 
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default MainImage
