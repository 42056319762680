import React from 'react' 
import { useParams } from 'react-router-dom';
import AgendaBody from '../agenda-body/AgendaBody';
import EventSession from '../agenda-sessions/EventSession';
import NoMatch from '../../../no-match/NoMatch';


const Programme = props => {
    const { session } = useParams();
    const {  event_id } = props 
     

    if (session === undefined) {
        return  <AgendaBody event_id={event_id} session={session} url_en='/en/programme/agenda'  url_pt_br='/pt-br/programa/agenda' url_es='/es/programa/agenda' /> 
    }
    if (session === 'tutoriales' || session === 'tutorials' || session === 'tutoriais') {
        return <EventSession session_id={'793'} current_session='101' />
    }
    if (session === 'first') {
        return <EventSession session_id={'2080'} current_session='160'/>
    }
    if (session === 'reunion-de-lac-csirts' || session === 'csirts-meeting' || session === 'reuniao-lac-csirts') {
        return <EventSession session_id={'791'} current_session='138'/>
    }
    if (session === 'plenaria' || session === 'plenary' || session === 'plenario') {
        return <EventSession session_id={'790'} current_session='100'/>
    }
    if (session === 'orientacion-para-nuevos-participantes' || session === 'session-for-new-participants' || session === 'sessao-para-novos-participantes') {
        return <EventSession session_id={'789'} current_session='103'/>
    }
    if (session === 'lacnog') {
        return <EventSession session_id={'788'} current_session='111'/>
    }   
    if (session === 'foro-publico-de-politicas' || session === 'public-policy-forum' || session === 'forum-publico-de-politicas') {
        return <EventSession session_id={'784'} current_session='137'/>
    } 
    if (session === 'foro-tecnico-de-lacnic' || session === 'lacnic-technical-forum' || session === 'forum-tecnico-de-lacnic') {
        return <EventSession session_id={'785'} current_session='104'/>
    }  
    if (session === 'espacio-dns-en-lac' || session === 'dns-space-at-lac' || session === 'espaco-dns-na-lac') {
        return <EventSession session_id={'2395'} current_session='161'/>
    }   
    if (session === 'seminario-gobernanza-de-internet' || session === 'internet-governance-seminar' || session === 'seminario-de-governanca-da-internet') {
        return <EventSession session_id={'2863'} current_session='189'/>
    }   
    if (session === 'lac-peering-forum') {
        return <EventSession session_id={'787'} current_session='116'/>
    }
    if (session === 'lactld') {
        return <EventSession session_id={'2865'} current_session='191'/>
    }
    if (session === 'lac-ix') {
        return <EventSession session_id={'2871'} current_session='193'/>
    }
    if (session === 'asamblea-general-asociados-de-lacnic' || session === 'lacnic-general-member-assembly' || session === 'assembleia-geral-de-associados-de-lacnic') {
        return <EventSession session_id={'783'} current_session='107'/>
    }  
    if (session === 'caribnog') {
        return <EventSession session_id={'2868'} current_session='192'/>
    }  
    
    else return <NoMatch />
}

export default Programme

