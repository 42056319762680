import React, { useState, useEffect } from "react";

export default function VideoSummary() {
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const response = await fetch(
          "https://apievt.lacnic.net/wp-json/wp/v2/media/2998"
        );
        const data = await response.json();
        setVideoUrl(data.guid.rendered);
      } catch (error) {
        console.error("Error fetching video URL:", error);
      }
    };

    fetchVideoUrl();
  }, []);

  return (
    <div>
      {videoUrl && (
        <video
          width="1000"
          height="240"
          className="about_lacnic38_video"
          autoPlay
          loop
          muted
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      )}
    </div>
  );
}
