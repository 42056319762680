import React from 'react';
import { useParams } from 'react-router-dom';
import FellowshipProgramBody from './FellowshipProgramBody';
import './fellowship-program.css'

const LacnicFellowshipProgram = props => {
    const { fellowship } = useParams()

    if (fellowship === 'objetivos' || fellowship === 'purpose' || fellowship === undefined) {
        return (
            <FellowshipProgramBody page_number='1'
                url_en={`/en/how-to-participate/lacnic-fellowship-program`}
                url_pt_br='/pt-br/como-participar/programa-de-bolsas-do-lacnic'
                url_es='/es/como-participar/programa-de-becas-de-lacnic'  
                {...props} />
        )
    }
    if (fellowship === 'criterios' || fellowship === 'selection-criteria') {
        return (
            <FellowshipProgramBody page_number='2'
                url_en={`/en/how-to-participate/lacnic-fellowship-program/selection-criteria`}
                url_pt_br='/pt-br/como-participar/programa-de-bolsas-do-lacnic/criterios'
                url_es='/es/como-participar/programa-de-becas-de-lacnic/criterios'
                {...props} />
        )
    }
    if (fellowship === 'obligaciones-de-los-postulantes-seleccionados' || fellowship === 'obligations-of-the-successful-applicants' || fellowship === 'obrigacoes-dos-candidatos-selecionados') {
        return (
            <FellowshipProgramBody page_number='3'
                url_en={`/en/how-to-participate/lacnic-fellowship-program/obligations-of-the-successful-applicants`}
                url_pt_br='/pt-br/como-participar/programa-de-bolsas-do-lacnic/obrigacoes-dos-candidatos-selecionados'
                url_es='/es/como-participar/programa-de-becas-de-lacnic/obligaciones-de-los-postulantes-seleccionados'
                {...props} />
        )
    }
    if (fellowship === 'alcance' || fellowship === 'scope') {
        return (
            <FellowshipProgramBody page_number='4'
                url_en={`/en/how-to-participate/lacnic-fellowship-program/scope`}
                url_pt_br='/pt-br/como-participar/programa-de-bolsas-do-lacnic/alcance'
                url_es='/es/como-participar/programa-de-becas-de-lacnic/alcance'
                {...props} />
        )
    }
    if (fellowship === 'fechas-importantes' || fellowship === 'important-dates' || fellowship === 'datas-importantes') {
        return (
            <FellowshipProgramBody page_number='5'
                url_en={`/en/how-to-participate/lacnic-fellowship-program/important-dates`}
                url_pt_br='/pt-br/como-participar/programa-de-bolsas-do-lacnic/datas-importantes'
                url_es='/es/como-participar/programa-de-becas-de-lacnic/fechas-importantes'
                {...props} />
        )
    } 
    if (fellowship === 'postulantes-seleccionados' || fellowship === 'selected-fellows' || fellowship === 'candidatos-selecionados' ) {
        return (
            <FellowshipProgramBody page_number='6' 
                url_en={`/en/how-to-participate/lacnic-fellowship-program/selected-fellows`}
                url_pt_br='/pt-br/como-participar/programa-de-bolsas-do-lacnic/candidatos-selecionados'
                url_es='/es/como-participar/programa-de-becas-de-lacnic/postulantes-seleccionados'
                {...props} />
        )
    } 
}

export default LacnicFellowshipProgram
