import React, {useContext} from 'react';
import SponsorList from '../../../components/sections/sponsors/SponsorList'
import SponsorsWrapper from '../../../components/sections/sponsors/SponsorsWrapper'
import UseLang from '../../../components/hook/UseLang';
import { SponsorContext } from '../../../context/DataContext';

function AboutSponsorsContent() {
    const { sponsors_lacnic40 } = useContext(SponsorContext) 
    return (
        <SponsorsWrapper className="about_sponsors_content">
            <UseLang es>
                <h2>Patrocinadores</h2>
                <p>Los siguientes fueron nuestros patrocinadores en el pasado evento:</p>
            </UseLang>
            <UseLang en>
                <h2>Sponsors</h2>
                <p>Sponsors of our previous event:</p>
            </UseLang>
            <UseLang pt_br>
                <h2>Patrocinadores</h2>
                <p>No evento passado, os nossos patrocinadores foram: </p>
            </UseLang> 
            <SponsorList sponsors={sponsors_lacnic40} className="sponsors_last_event" />
            <UseLang es>
                <p>Ofrecemos un espacio de stands donde puedes conocer de primera mano los
                    servicios que ofrecen, así como presentaciones comerciales para que conozcas las novedades de cada patrocinador.</p>
            </UseLang>
            <UseLang en>
                <p>We offer a space for stands where you can learn first-hand about the services offered by our sponsors,
                    as well as commercial presentations where you can find out each sponsor's latest news.</p>
            </UseLang>
            <UseLang pt_br>
                <p>Oferecemos um espaço para estandes onde você poderá conhecer em primeira mão os serviços que estes oferecem,
                    além de apresentações comerciais para que você conheça as novidades de cada patrocinador.</p>
            </UseLang>
        </SponsorsWrapper>
    )
}

export default AboutSponsorsContent
