import React, { useContext, useState } from 'react';
import { PostContext } from '../../../context/DataContext'; 
import UseLangLocation from '../../../components/hook/UseLangLocation'
import UsePost from '../../../components/hook/UsePost'
import Main from '../../../components/interface/main/Main'
import Footer from '../../../components/navegation/footer/Footer'
import Header from '../../../components/navegation/header/Header'
import UseTimeOut from '../../../components/hook/UseTimeOut';
import UseHTML from '../../../components/hook/UseHTML';
import './transport.css'
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';

const Transport = props => {
    const { handleID, title, handleApiURL, handleLoading, status, content } = useContext(PostContext);
    const [loading, setLoading] = useState(true);

    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/travel' handleLoading={handleLoading} handleID={handleID} id='3233'>
                <Header title={title} loading={loading} url_en='/en/hotels-and-travel/transportation-services' url_es='/es/hoteles-y-viaje/servicio-de-traslados' url_pt_br='/pt-br/hoteis-e-viagem/servico-de-transporte' {...props} />
                <Main className='container main_transport' loading={loading} status={status}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={title}>
                             
                            <SponsorsSlider/>
                            <StickyHeader/>
                            <article>
                                <div> 
                                    <h1>{title}</h1>
                                    <UseHTML html={content} />
                                </div>
                            </article>
                        </UseTimeOut>
                    </section> 
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default Transport
