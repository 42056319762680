import React from 'react';
import Organizers from '../../../components/design/organizers/Organizers';
import UseHTML from '../../../components/hook/UseHTML';
import UseLang from '../../../components/hook/UseLang';

const WelcomeContent = props => {
    const { onClick, about_event, organizers } = props;
 

    return (
        <article className='welcome'>
            <div>
                <div className='about_event'>
                    <>
                     
                        <UseHTML html={about_event} />
                         
                        <UseLang es><p>Mantente al tanto de nuestras<button onClick={onClick}> novedades</button></p></UseLang>
                        <UseLang en><p>Keep up with our <button onClick={onClick}> latest news </button></p> </UseLang>
                        <UseLang pt_br> <p>Fique por dentro das nossas   <button onClick={onClick}> novidades </button></p></UseLang>
                        <Organizers organizers={organizers} />
                    </>
                </div>
            </div>
        </article>

    )
}

export default WelcomeContent
