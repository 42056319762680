import React, { useState, useEffect, useContext } from "react";
import SocialActivities from "../social-activities/SocialActivities";
import TableCell from "./TableCell";
import "./timetable.css";
import { SessionContext, SocialEventContext } from "../../../../context/DataContext";
import TableRow from "./TableRow";

const Timetable = (props) => {
    const { eventTime } = props;
    const [socialActivity, setSelectedSocialActivity] = useState(null);
    const [display, setDisplay] = useState(false);
    const [socialSessionId, setSocialSessionId] = useState(null);

    // Verificar si los datos para las dos sesiones existen
    const { socialExists } = useContext(SocialEventContext); 
    const isRunners = socialExists.runnersExists;
    const isCocktail = socialExists.cocktailExists; 
    const isSocialEvent = socialExists.socialEventExists;
    
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const option = params.get("socialActivity"); 
        const id = params.get("id"); 
        if (option) {
            if (option === "lacnic-runners" || option === "evento-social" || option === "coctel-de-bienvenida" || option === "beer&gear" || option === "beer-and-gear" || option === "beer-and-peer") { 
                setSocialSessionId(id)
                setSelectedSocialActivity(option);
                setDisplay(true);
            } else {
                window.location.href = "/error";
            }
        }
    }, [display]);

    const handleSocial = (option, id) => {
        setSocialSessionId(id);
        setSelectedSocialActivity(option);
        setDisplay(true);
         

        // Actualizar los parámetros de consulta en la URL
        const params = new URLSearchParams({
            id: id,
            socialActivity: option, 
        });
        window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
    };

    const closeModal = () => {
        setDisplay(false);

        // Actualizar los parámetros de consulta en la URL
        const params = new URLSearchParams(window.location.search);
        params.delete("socialActivity");
        params.delete("display");
        window.history.replaceState(null, null, window.location.href.split("?")[0]);
    };

    // Obtener la fecha seleccionada de la URL 
    const { selectedDateStr, tableStyles } = useContext(SessionContext);

    // IMPORTANTE: selectedDateStr tiene que ser igual day para que al seleccionar DayPicker se active la funcionalidad 
  
    return (

        <article>
            <div className="table_bleed">
                <div className="boxed_agenda">
                    <table className={`timetable ${selectedDateStr ? tableStyles : ''}`}>
                        <tbody>

                            <TableRow id="Row1" selectedDateStr={selectedDateStr}>
                                <TableCell type="th" header="off" item="header-time" width="68px" day={selectedDateStr} eventTime={eventTime} {...props} />
                                <TableCell type="th" item="header-date" width="70px" day="05/05/2024" day_name="sunday" date="5" {...props} />
                                <TableCell type="th" item="header-date" width="275px" day="06/05/2024" day_name="monday" date="6" colspan="4" {...props} />
                                <TableCell type="th" item="header-date" width="100px" day="07/05/2024" day_name="tuesday" date="7" colspan="2" {...props} />
                                <TableCell type="th" item="header-date" width="156px" day="08/05/2024" day_name="wednesday" date="8" colspan="3" {...props} />
                                <TableCell type="th" item="header-date" width="244px" day="09/05/2024" day_name="thursday" date="9" colspan="5" {...props} />
                                <TableCell type="th" item="header-date" width="136px" day="10/05/2024" day_name="friday" date="10" colspan="2" {...props} />

                            </TableRow>
                            <> 
                            {isRunners ? 
                                <>
                                <TableRow id='Row2'>
                                    <TableCell type='td' item='time' day={selectedDateStr} time='06/05/2024 06:30' eventTime={eventTime} {...props} />
                                    <TableCell type='td' item='empty' {...props} />
                                    <TableCell type='td' item='empty' colspan="4" {...props} />
                                    <TableCell type='td' item='empty' colspan="2"  {...props} />
                                    <TableCell type='social' item='activity' social={() => handleSocial('lacnic-runners', '198')}   name='LACNIC Runners' colspan="3" day="08/05/2024" start_time="06:30" end_time="07:30"  {...props} />
                                    <TableCell type='td' item='empty' colspan="4" {...props} />
                                    <TableCell type='td' item='empty' {...props} />
                                </TableRow>
                                <TableRow id='Row3'>
                                    <TableCell type='td' item='time' day={selectedDateStr} time='06/05/2024 07:30' eventTime={eventTime} {...props} />
                                    <TableCell type='td' item='empty' {...props} />
                                    <TableCell type='td' item='empty' colspan="4" {...props} />
                                    <TableCell type='td' item='empty' colspan="2" {...props} />
                                    <TableCell type='td' item='empty' colspan="3"   {...props} />
                                    <TableCell type='td' item='empty' colspan="4"  {...props} />
                                    <TableCell type='td' item='empty' {...props} />
                                </TableRow>
                                </>
                            : 
                            undefined}
                            </>

                            <TableRow id="Row4" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 08:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty"  {...props} />
                                <TableCell type="td" item="activity" name="Registro" colspan={selectedDateStr !== "" ? "4" : "1"} day="06/05/2024" start_time="08:00" end_time="08:30" {...props} />
                                <TableCell type="td" item="activity" name="Registro" day="07/05/2024" start_time="08:00" end_time="08:30" colspan="2"{...props} />
                                <TableCell type="td" item="activity" name="Registro" colspan={selectedDateStr !== "" ? "3" : "1"} day="08/05/2024" start_time="08:00" end_time="08:30"{...props} />
                                <TableCell type="td" item="empty" colspan={selectedDateStr !== "" ? "5" : "1"} start_time="08:00" end_time="08:30"{...props} />
                                <TableCell type="td" item="empty" {...props} />
                            </TableRow>

                            <TableRow id="Row5" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 08:30" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="activity" name="Nuevos participantes" day="06/05/2024" start_time="08:30" end_time="09:00" colspan="4" {...props} />
                                <TableCell type="td" item="activity" name="Apertura" day="07/05/2024" start_time="09:00" end_time="11:00" colspan="2"  {...props} />
                                <TableCell type="td" item="empty" colspan="3" {...props} />
                                <TableCell type="td" item="empty" colspan="5" {...props} />
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                            </TableRow>
                            <TableRow id="Row6" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 09:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                {/* lunes */}
                                <TableCell type="td" item="activity" name="Tutoriales" day="06/05/2024" start_time="09:00" end_time="11:00" colspan="2" {...props} />
                                <TableCell type="td" item="activity" name="LAC PF" day="06/05/2024" start_time="09:00" end_time="11:00" colspan="2" {...props} />
                                {/* martes */}
                                <TableCell type="td" item="activity" name="LACNIC" day="07/05/2024" start_time="09:00" end_time="11:00"  {...props} />
                                <TableCell type="td" item="activity" name="LACTLD" day="07/05/2024" start_time="09:00" end_time="11:00"  {...props} />
                                {/* miercoles */}
                                <TableCell type="td" item="activity" name="LACNIC" day="08/05/2024" start_time="09:00" end_time="11:00"  {...props} />
                                <TableCell type="td" item="activity" name="LACTLD" day="08/05/2024" start_time="09:00" end_time="11:00"   {...props} />
                                <TableCell type="td" item="activity" name="LAC CSIRTs" day="08/05/2024" start_time="09:00" end_time="11:00" {...props} />
                                {/* jueves */}
                                <TableCell type="td" item="activity" name="FTL" day="09/05/2024" start_time="09:00" end_time="11:00" colspan="2"  {...props} />
                                <TableCell type="td" item="activity" name="LACTLD" day="09/05/2024" start_time="09:00" end_time="11:00" {...props} />
                                <TableCell type="td" item="activity" name="LAC CSIRTs" day="09/05/2024" start_time="09:00" end_time="11:00"   {...props} />
                                <TableCell type="td" item="activity" name="CaribNOG" day="09/05/2024" start_time="09:00" end_time="11:00"  {...props} />
                                {/* viernes */}
                                <TableCell type="td" item="activity" name="WorkingGroup" day="10/05/2024" start_time="09:00" end_time="11:00" colspan="2" {...props} />
                            </TableRow>
                            <TableRow id="Row7" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 11:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="activity" name="Break" colspan="4" day="06/05/2024" start_time="11:00" end_time="11:30" {...props} />
                                <TableCell type="td" item="activity" name="Break" day="07/05/2024" start_time="11:00" end_time="11:30" colspan="2" {...props} />
                                <TableCell type="td" item="activity" name="Break" colspan="3" day="08/05/2024" start_time="11:00" end_time="11:30" {...props} />
                                <TableCell type="td" item="activity" name="Break" colspan="5" day="09/05/2024" start_time="11:00" end_time="11:30" {...props} />
                                <TableCell type="td" item="activity" name="Break" day="10/05/2024" start_time="11:00" end_time="11:30" colspan="2" {...props} />
                            </TableRow>
                            <TableRow id="Row8" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 11:30" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                {/* Lunes */}
                                <TableCell type="td" item="activity" name="Tutoriales" day="06/05/2024" start_time="11:30" end_time="13:00" colspan="2" {...props} />
                                <TableCell type="td" item="activity" name="LAC PF" day="06/05/2024" start_time="11:30" end_time="13:00" colspan="2" {...props} />
                                {/* martes */}
                                <TableCell type="td" item="activity" name="FTL" day="07/05/2024" start_time="11:30" end_time="13:00"  {...props} />
                                <TableCell type="td" item="activity" name="LACTLD" day="07/05/2024" start_time="11:30" end_time="13:00"   {...props} />
                                {/* miercoles */}
                                <TableCell type="td" item="activity" name="Foro Público" day="08/05/2024" start_time="11:30" end_time="13:00"  {...props} />
                                <TableCell type="td" item="activity" name="LACTLD" day="08/05/2024" start_time="11:30" end_time="13:00"  {...props} />
                                <TableCell type="td" item="activity" name="LAC CSIRTs" day="08/05/2024" start_time="11:30" end_time="13:00"  {...props} />
                                {/* jueves */}
                                <TableCell type="td" item="activity" name="FTL" day="09/05/2024" start_time="11:30" end_time="13:00" colspan="2"  {...props} />
                                <TableCell type="td" item="activity" name="LACTLD" day="09/05/2024" start_time="11:30" end_time="13:00" {...props} />
                                <TableCell type="td" item="activity" name="LAC CSIRTs" day="09/05/2024" start_time="11:30" end_time="13:00"   {...props} />
                                <TableCell type="td" item="activity" name="CaribNOG" day="09/05/2024" start_time="11:30" end_time="13:00"   {...props} />
                                {/* viernes */}
                                <TableCell type="td" item="activity" name="LACNOG" day="10/05/2024" start_time="11:30" end_time="13:00" colspan="2"  {...props} />
                            </TableRow>
                            <TableRow id="Row9" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 13:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" rowspan="2" {...props} />
                                <TableCell type="td" item="activity" name="Lunch" day="06/05/2024" start_time="13:00" end_time="14:00" colspan="4" rowspan="2"  {...props} />
                                <TableCell type="td" item="activity" name="Lunch" day="07/05/2024" start_time="13:00" end_time="14:00" colspan="2" rowspan="2" {...props} />
                                <TableCell type="td" item="activity" name="Lunch" day="08/05/2024" start_time="13:00" end_time="14:00" colspan="3" rowspan="2" {...props} />
                                <TableCell type="td" item="activity" name="Lunch" day="09/05/2024" start_time="13:00" end_time="14:00" colspan="5" rowspan="2" {...props} />
                                <TableCell type="td" item="activity" name="Cierre" day="10/05/2024" start_time="13:00" end_time="14:00" colspan="2"  {...props} />

                            </TableRow>
                            <TableRow id="Row9-1" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 13:30" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="activity" name="Lunch" day="10/05/2024" start_time="13:30" end_time="14:00" colspan="2" rowspan="2" {...props} />
                            </TableRow>
                            <TableRow id="Row10" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 14:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" rowspan="3" {...props} />
                                {/* lunes */}
                                <TableCell type="td" item="activity" name="Tutoriales" day="06/05/2024" start_time="14:00" end_time="16:00" colspan="2" rowspan="3" {...props} />
                                <TableCell type="td" item="activity" name="LAC PF" day="06/05/2024" start_time="14:00" end_time="16:00" colspan="1" rowspan="3" {...props} />
                                <TableCell type="td" item="activity" name="LAC CSIRTs" day="06/05/2024" start_time="14:00" end_time="16:00" colspan="1" rowspan="3" {...props} />
                                {/*  martes */}
                                <TableCell type="td" item="activity" name="FTL" day="07/05/2024" start_time="14:00" end_time="16:00" rowspan="3" {...props} />
                                <TableCell type="td" item="activity" name="LACTLD" day="07/05/2024" start_time="09:00" end_time="11:00" rowspan="3" {...props} />
                                {/* miercoles */}
                                <TableCell type="td" item="activity" name="Foro Público" day="08/05/2024" start_time="14:00" end_time="16:00" rowspan="2" {...props} />
                                <TableCell type="td" item="activity" name="LACTLD" day="08/05/2024" start_time="14:00" end_time="16:00" rowspan="2" {...props} />
                                <TableCell type="td" item="activity" name="LAC CSIRTs" day="08/05/2024" start_time="14:00" end_time="16:00" rowspan="2" {...props} />
                                {/* jueves */}
                                <TableCell type="td" item="activity" name="FTL" day="09/05/2024" start_time="14:00" end_time="16:00" rowspan="3" colspan="2" {...props} />
                                <TableCell type="td" item="activity" name="LACTLD" day="09/05/2024" start_time="14:00" end_time="16:00" rowspan="3" {...props} />
                                <TableCell type="td" item="activity" name="LAC CSIRTs" day="09/05/2024" start_time="14:00" end_time="16:00" rowspan="3" {...props} />
                                <TableCell type="td" item="activity" name="Tutoriales" day="09/05/2024" start_time="14:00" end_time="16:00" rowspan="3" {...props} />
                                {/* viernes */}

                            </TableRow>
                            <TableRow id="Row10-1" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 14:30" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="activity" name="presentFuture" day="10/05/2024" start_time="14:30" end_time="16:00" colspan="2" rowspan="2" {...props} />
                            </TableRow>
                            <TableRow id="Row11" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 15:30" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="activity" name="Break" colspan="3" day="08/05/2024" start_time="15:30" end_time="16:00" {...props} />
                            </TableRow>
                            <TableRow id="Row12" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 16:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="activity" name="Registro" rowspan="3" day="05/05/2024" start_time="16:00" end_time="18:00" {...props} />
                                <TableCell type="td" item="activity" name="Break" colspan="4" {...props} day="06/05/2024" start_time="16:00" end_time="16:30" {...props} />
                                <TableCell type="td" item="activity" name="Break" day="07/05/2024" start_time="16:00" end_time="16:30" colspan="2"{...props} />
                                {/* miercoles */}
                                <TableCell type="td" item="activity" name="Assembly" day="08/05/2024" start_time="16:00" end_time="18:00" rowspan="3"  {...props} />
                                <TableCell type="td" item="activity" name="LACTLD" day="08/05/2024" start_time="16:00" end_time="18:00" rowspan="3" {...props} />
                                <TableCell type="td" item="activity" name="LAC CSIRTs" day="08/05/2024" start_time="16:00" end_time="18:00" rowspan="3" {...props} />
                                {/* jueves */}
                                <TableCell type="td" item="activity" name="Break" colspan="5" day="09/05/2024" start_time="16:00" end_time="16:30" {...props} />
                                <TableCell type="td" item="activity" name="Break" day="10/05/2024" colspan="2" start_time="16:00" end_time="16:30" {...props} />
                            </TableRow>
                            <TableRow id="Row13" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 16:30" eventTime={eventTime} {...props} />
                                {/* lunes */}
                                <TableCell type="td" item="activity" name="Tutoriales" day="06/05/2024" start_time="16:30" end_time="18:00" colspan="2" rowspan="2" {...props} />
                                <TableCell type="td" item="activity" name="LAC PF" day="06/05/2024" start_time="16:30" end_time="18:00" colspan="2" rowspan="2"  {...props} />
                                 {/* martes */}
                                <TableCell type="td" item="activity" name="FTL" day="07/05/2024" start_time="16:30" end_time="18:00" rowspan="2" {...props} />
                                <TableCell type="td" item="activity" name="LACTLD" day="07/05/2024" start_time="16:30" end_time="18:00" rowspan="2" {...props} />

                                {/* jueves */}
                                <TableCell type="td" item="activity" name="LACNIC" day="09/05/2024" start_time="16:30" end_time="18:00" rowspan="2" colspan="2"  {...props} />
                                <TableCell type="td" item="activity" name="LACTLD" day="09/05/2024" start_time="16:30" end_time="18:00" rowspan="2" {...props} />
                                <TableCell type="td" item="activity" name="LAC CSIRTs" day="09/05/2024" start_time="16:30" end_time="18:00" rowspan="2"  {...props} /> 
                                <TableCell type="td" item="activity" name="Tutoriales" day="09/05/2024" start_time="16:30" end_time="18:00" rowspan="2" {...props} />

                                {/* viernes */}

                            </TableRow>
                            <TableRow id="Row14" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 17:00" eventTime={eventTime} {...props} />
                            </TableRow>

                            <TableRow id="Row15" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 18:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="social" item="activity" {...(isCocktail ? { social: () => handleSocial('coctel-de-bienvenida', '199') } : null)}  name="Cóctel de Bienvenida" day="06/05/2024" start_time="18:00" end_time="22:00" colspan="4" rowspan="5" {...props} />
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                                <TableCell type="td" item="empty" {...props} colspan="3" />
                                <TableCell type="td" item="empty" {...props} colspan="5" />
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                            </TableRow>
                            <TableRow id="Row16" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 18:30" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                                <TableCell type="td" item="empty" colspan="3" {...props} />
                                <TableCell type="social" item="empty" colspan="5"  {...props} />
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                            </TableRow>
                            <TableRow id="Row17" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 19:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                                <TableCell type="social" item="activity" {...(isSocialEvent ? { social: () => handleSocial('evento-social', '200') } : null)} name="Evento Social" day="08/05/2024" start_time="19:00" end_time="00:00" colspan="3" rowspan="5" {...props} />
                                <TableCell type="social" item="empty" colspan="5"  {...props} />
                                <TableCell type="td" item="empty" colspan="2"{...props} />
                            </TableRow>
                            <TableRow id="Row18" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 20:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                                <TableCell type="td" item="empty" {...props} colspan="5" />
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                            </TableRow>
                            <TableRow id="Row19" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 21:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty"  {...props} />
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                                <TableCell type="td" item="empty" colspan="5" {...props} />
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                            </TableRow>
                            <TableRow id="Row20" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 22:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty"  {...props} />
                                <TableCell type="td" item="empty" colspan="4" {...props} />
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                                <TableCell type="td" item="empty" colspan="5" {...props} />
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                            </TableRow>
                            <TableRow id="Row21" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="06/05/2024 00:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="empty" colspan="4" {...props} />
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                                <TableCell type="td" item="empty" colspan="5" {...props} />
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                            </TableRow>

                        </tbody>
                    </table>
                </div>
                {selectedDateStr ? '' : <i className="icon-mouse-scroll-left-right icon"></i>}
                <SocialActivities display={display} closeModal={closeModal} socialActivity={socialActivity} socialSessionId={socialSessionId}/>
            </div>
        </article>
    );
};

export default Timetable;
