import React, { useState, useEffect } from 'react';
import { MenuContext } from './DataContext';
import axios from "axios";

export const MenuState = ({ children }) => {

    let langdefault = 'es'
    const [menuLang, setMenuLang] = useState(langdefault);

    const menuURL = 'https://apievt.lacnic.net/wp-json/menus/v1/menus';
    const eventName = 'lacnic41'

    const [isMenu, setMenu] = useState([])
    const [loading, setLoadApiMenuData] = useState(false) 

    useEffect(() => { 
        const loadOurEvents = async () => {
            if (!menuLang) {
                return; // salir si menuLang es undefined o null
              }
            setLoadApiMenuData(true) 
            //https://apievt.lacnic.net/wp-json/menus/v1/menus/lacnic41-es
            const responseJSON = await axios.get(`${menuURL}/${eventName}-${menuLang}`)
            
            setMenu(responseJSON.data)
            setLoadApiMenuData(false)
        }
        loadOurEvents()
    }, [menuURL, menuLang])
    
    const handleMenuLang = (value) => {
        setMenuLang(value)
    }
    
    const [eventID, setEventID] = useState(157);
    const eventURL = 'https://apievt.lacnic.net/wp-json/wp/v2/our_events';

    const [id, setID] = useState([])

    useEffect(() => {
        const loadEvent = async () => {
            const responseJSON = await axios.get(`${eventURL}/${eventID}`)
            setID(responseJSON.data.id)
        }
        loadEvent();
    }, [eventURL, eventID])
    const handleEventID = (event_value) => {
        setEventID(event_value)
    }

    const [isMenuApp, setMenuApp] = useState([])
    const [isLoadMenuApp, setLoadMenuApp]  = useState(false)
    useEffect(() => { 
        const loadOurEvents = async () => {
            if (!menuLang) {
                return; // salir si menuLang es undefined o null
              }
              setLoadMenuApp(true) 
            //https://apievt.lacnic.net/wp-json/menus/v1/menus/lacnic41-es
            const responseJSON = await axios.get(`${menuURL}/lacnic41-app-${menuLang}`)
            setMenuApp(responseJSON.data)
            setLoadMenuApp(false)
        }
        loadOurEvents()
    }, [menuURL, menuLang]) 

    return (
        <MenuContext.Provider value={{
            handleMenuLang, handleEventID, isMenu, menuLang, id, loading, eventID, isLoadMenuApp, isMenuApp
        }}>
            {children}
        </MenuContext.Provider>
    );
}

