import React, { useEffect, useState } from 'react';
import UseLang from '../../hook/UseLang'; 

const TimerCountDown = () => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setUTCHours(14, 30, 0, 0);
    const difference = tomorrow - now;
    
    let timeLeft = {};
  
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
  
    return timeLeft;
  };



  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const fechaActual = new Date();
  // Definir la fecha objetivo del evento tiene que ser dos días antes del comienzo del evento
  const fechaObjetivo = new Date(2024, 4, 6); // Nota: Los meses en JavaScript van de 0 a 11, por lo que mayo es 4

  // Verificar si la fecha actual es igual o pasó el día objetivo
  const esFechaObjetivo = fechaActual.getDate() >= fechaObjetivo.getDate();  

  return (
    <>
        {!esFechaObjetivo ? <div className='myTimerCount'>  
            <h2>
                <UseLang es>Comienza en:</UseLang>
                <UseLang en>Starting in:</UseLang>
                <UseLang pt_br>Começa en:</UseLang>
            </h2>
            <div>
                <div>{timeLeft.hours}<span>h</span></div>
                <div>{timeLeft.minutes}<span>m</span></div>
                <div>{timeLeft.seconds}<span>s</span></div>
            </div>
        </div>: null}
    </>
  );
};

export default TimerCountDown;
