// config.js
const config = {
    currentEvent: "LACNIC 41",
    pastEvent: "LACNIC 40",
    // ID del evento actual: https://apievt.lacnic.net/wp-admin/edit-tags.php?taxonomy=our_events&post_type=agenda
    currentEventID: 179, 
    currentEventID_en: 181, 
    currentEventID_pt_br: 182,
    // Archivos de configuración Zona Horaria: HourRow.jsx, TimeZoneState.jsx
    eventTimeZone: 'America/Panama',
    utcEventTimeZone: 'GMT-0500',
    currentEventCountry: 'Panamá',
    // URLs importantes
    registrationURL: 'https://eventos.lacnic.net/ev4/detail?id=lacnic-41'
  };
  
export default config;
  