import React, {useContext} from 'react'; 
import SponsorList from '../../../components/sections/sponsors/SponsorList';
import SponsorsWrapper from '../../../components/sections/sponsors/SponsorsWrapper';
import UseLang from '../../../components/hook/UseLang';
import { SponsorContext } from '../../../context/DataContext';

function SponsorsContent(props) { 
    const { sponsors_lacnic41 } = useContext(SponsorContext)    
    return (
        <>{sponsors_lacnic41 && Array.isArray(sponsors_lacnic41) && sponsors_lacnic41.length > 0 ? 
        <SponsorsWrapper>
            <h2>
                <UseLang es>Patrocinadores</UseLang>
                <UseLang en>Sponsors</UseLang>
                <UseLang pt_br>Patrocinadores</UseLang>
            </h2>
            <SponsorList sponsors={sponsors_lacnic41} />
        </SponsorsWrapper> : undefined}</>
    )
    
}

export default SponsorsContent
