import React, {  useEffect } from 'react'; 
import './suscribe-form.css'
import SubscriptionForm from './SubscriptionForm'; 

const SuscribeForm = props => { 

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.createsend1.com/javascript/copypastesubscribeformlogic.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <div className='suscribe_form'>
                <img src="/images/lacnic-blog.svg" alt='LACNIC Blog' /> 
                <SubscriptionForm/>
            </div>
        </>

    )
}

export default SuscribeForm
