import React, { useContext } from 'react'
import UseLang from '../../../../components/hook/UseLang';
import { SessionContext } from '../../../../context/DataContext';
import ActivityDetails from './ActivityDetails';
import RoomSlot from './RoomSlot';
import ActivityTime from './ActivityTime';
import config from '../../../../components/configuration/config';

const ActivitySlot = props => {
    const { item, name, start_time, end_time, day } = props;
    const { selectedDateStr, agendaLACNIC40} = useContext(SessionContext);
 
    const filteredBySessionName = agendaLACNIC40?.filter((item) => {
        return Array.isArray(item.acf.sessions) && item.acf.sessions.some((session) => {
            return session.name === "Tutoriales";
        });
    });
    
      
      const monday14H = filteredBySessionName.filter((item) => {
        return item.acf.start_time === "06/05/2024 14:00";
      });
      const Admin = filteredBySessionName.filter((item) => {
        return item?.acf?.start_time === "06/05/2024 09:00" &&
         item?.acf?.ending_time === "06/05/2024 11:00";
      });
      const policySession = filteredBySessionName.filter((item) => {
        return (
            item?.acf?.start_time === "06/05/2024 11:30" &&
            item?.acf?.ending_time === "06/05/2024 13:00"
          );
      });  
      const thursday14H = filteredBySessionName.filter((item) => {
        return item.acf.start_time === "09/05/2024 14:00";
      });  
      const ipv6Sessions = filteredBySessionName.filter((item) => {
        return (
          item?.acf?.start_time === "06/05/2024 11:30" &&
          item?.acf?.ending_time === "06/05/2024 18:00"
        );
      }); 
      const routing = filteredBySessionName.filter((item) => {
        return (
          item?.acf?.start_time === "06/05/2024 09:00" &&
          item?.acf?.ending_time === "06/05/2024 13:00"
        );
      }); 
      const consulting = filteredBySessionName.filter((item) => {
        return (
          item?.acf?.start_time === "09/05/2024 16:30" &&
          item?.acf?.ending_time === "09/05/2024 18:00"
        );
      }); 

    if (item === 'activity') {
        return (
            <div>
                {
                    name === 'Tutoriales' &&
                    <> 
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        {selectedDateStr ? 
                            <div className='TutorialsGrid'> 
                                { (`${day} ${start_time}` === '06/05/2024 14:00' || `${day} ${start_time}` === '06/05/2024 16:30'  ) &&  <ActivityDetails activity={monday14H} />}
                                  
                                 {(`${day} ${start_time}` === '06/05/2024 11:30' || `${day} ${start_time}` === '06/05/2024 14:00' || `${day} ${end_time}` === '06/05/2024 18:00')  && <ActivityDetails activity={ipv6Sessions} />}   
                                 {(`${day} ${start_time}` === '06/05/2024 11:30' || `${day} ${end_time}` === '06/05/2024 13:00')  && <ActivityDetails activity={policySession} />}   
                                 {(`${day} ${start_time}` === '06/05/2024 09:00' || `${day} ${end_time}` === '06/05/2024 11:00')  && <ActivityDetails activity={Admin} />} 
                                 {(`${day} ${start_time}` === '06/05/2024 09:00' || `${day} ${end_time}` === '06/05/2024 13:00')  && <ActivityDetails activity={routing} />}   

                                {`${day} ${start_time}` === '09/05/2024 14:00' &&  <ActivityDetails activity={thursday14H} />}
                                {(`${day} ${start_time}` === '09/05/2024 16:30' || `${day} ${end_time}` === '09/05/2024 18:00') &&  <ActivityDetails activity={consulting} />}
                            </div>
                        : <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>Tutorials</UseLang>
                            <UseLang pt_br>Tutoriais</UseLang>
                            </div>
                        }
                         
                    </>
                }
                {
                    name === 'DNS' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>Espacio DNS en LAC</UseLang>
                            <UseLang en>DNS space at LAC</UseLang>
                            <UseLang pt_br>Espaço DNS na LAC</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Foyer (Piso 2)</RoomSlot>
                        }
                    </>
                }
                {name === 'Registro' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>Registration</UseLang>
                            <UseLang pt_br>Registro</UseLang>
                        </div>
                    </>
                }
                {
                    name === 'Nuevos participantes' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>New Participants</UseLang>
                            <UseLang pt_br>Novos participantes</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Salón 4 - Plenaria</RoomSlot>
                        }
                    </>
                }
                 

                {
                    name === 'Reunión Becados' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>Fellowship Program</UseLang>
                            <UseLang pt_br>Sessão bolsistas</UseLang>
                        </div>
                    </>
                }
                {
                    name === 'Apertura' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>Apertura / Plenaria</UseLang>
                            <UseLang en>Opening / Plenary</UseLang>
                            <UseLang pt_br>Abertura / Plenário</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Salón 4 - Plenaria</RoomSlot>
                        }
                    </>
                }
                {
                    name === 'Plenaria' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                        <div className='ActivityName'>
                            <UseLang es>Plenaria</UseLang>
                            <UseLang en>Plenary</UseLang>
                            <UseLang pt_br>Plenário</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Salón 4 - Plenaria</RoomSlot>
                        }
                    </>
                }
                {
                    name === 'Cierre' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                        <div className='ActivityName'>
                            <UseLang es>Cierre {config.currentEvent}</UseLang>
                            <UseLang en>LACNIC {config.currentEvent} Wrap-Up</UseLang>
                            <UseLang pt_br>Encerramento {config.currentEvent}</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Salón 4 - Plenaria</RoomSlot>
                        }
                    </>
                }
                {
                    name === 'FTL' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>Foro Técnico</UseLang>
                            <UseLang en>Technical Forum</UseLang>
                            <UseLang pt_br>Fórum Técnico</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Salón 4 - Plenaria</RoomSlot>
                        }
                    </>
                }
                {
                    name === 'Foro Público' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>Policy Forum</UseLang>
                            <UseLang pt_br>Fórum Público</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Salón 4 - Plenaria</RoomSlot>
                        }
                    </>
                }
                {
                    name === 'Assembly' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>Asamblea General</UseLang>
                            <UseLang en>General Assembly</UseLang>
                            <UseLang pt_br>Assembleia Geral</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Salón 4 - Plenaria</RoomSlot>
                        }
                    </>
                }

                {
                    name === 'presentFuture' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>Internet en {config.currentEventCountry}, presente y futuro</UseLang>
                            <UseLang en>Internet in {config.currentEventCountry}, present and future</UseLang>
                            <UseLang pt_br>Internet no {config.currentEventCountry}, presente e futuro</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Salón 4 - Plenaria</RoomSlot>
                        }
                    </>
                }

                {
                    name === 'Lunch' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                        <div className='ActivityName'>
                            <UseLang es>Almuerzo</UseLang>
                            <UseLang en>{name}</UseLang>
                            <UseLang pt_br>Almoço</UseLang>
                        </div>
                    </>
                }
                {
                    name === 'Cóctel de Bienvenida' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>Welcome Cocktail</UseLang>
                            <UseLang pt_br>Coquetel de Boas vindas</UseLang>
                        </div>
                    </>
                }
                {
                    name === 'Evento Social' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>Social Event</UseLang>
                            <UseLang pt_br>Evento Social</UseLang>
                        </div>
                    </>
                }
                {
                    name === 'Break' && 
                    <> 
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                        <div className='ActivityName'>
                            <UseLang es>Receso</UseLang>
                            <UseLang en>{name}</UseLang>
                            <UseLang pt_br>Intervalo</UseLang>
                        </div>
                    </>
                }
                {name === 'BoF' && 
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>BoF de mediciones</UseLang>
                            <UseLang en>Measure-<br/>ments BoF</UseLang>
                            <UseLang pt_br>BoF de Mediciones</UseLang>
                        </div>
                    </>
                }
                {
                    name === 'WorkingGroup' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>Grupo de trabajo de Mediciones de Internet</UseLang>
                            <UseLang en>Internet Measurements Working Group</UseLang>
                            <UseLang pt_br>Grupo de Trabalho de Medições da Internet</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Salón 4 - Plenaria</RoomSlot>
                        }
                    </>
                }
                {
                    name === 'SeminarioGobernanza' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es> Seminario Gobernanza de Internet</UseLang>
                            <UseLang en>Internet Governance Seminar</UseLang>
                            <UseLang pt_br>Seminário de Governança da Internet</UseLang>
                            
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Salón 8</RoomSlot>
                        }
                    </>
                }
                {name === 'CaribNOG' &&  <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>CaribNOG Day</div>
                    {selectedDateStr && 
                            <RoomSlot>Salón 6</RoomSlot>
                        }
                </>}
                {name === 'Beer&Gear' &&  <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>{name}</div>
                </>}
                {name === 'Beer&Peer' && <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>{name}</div> 
                </> }
                {name === 'First Conf.' && <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                    <div className='ActivityName'>{name}</div> 
                    {selectedDateStr && 
                    <RoomSlot>Foyer (Piso 2)</RoomSlot>
                    }
                </> }
                {name === 'First Training' &&   <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>{name}</div> 
                    {selectedDateStr &&  <RoomSlot>Foyer (Piso 2)</RoomSlot>}</>} 
                {name === 'First' && <>
                <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                    <div className='ActivityName'>{name}</div> </>}
                {name === 'LACNIC Runners' && <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>{name}</div> 
                </> }
                {name === 'LAC CSIRTs' && <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/>  
                    <div className='ActivityName'>{name} {day === "09/05/2024" && 'Training'} {day === "08/05/2024" && 'Conf.'}</div> 
                    {selectedDateStr && 
                            <RoomSlot>Salón 7</RoomSlot>
                    }
                </>} 
                {name === 'LAC PF' && <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>{name}</div> 
                    {selectedDateStr && 
                        <RoomSlot>Salón 5</RoomSlot>
                    }
                </> }
                {name === 'RISE Conf.' && <div className='ActivityName'>{name}</div> }
                {name === 'RISE Training' && <div className='ActivityName'>{name}</div> }
                {name === 'LACTLD' && <> 
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                    <div className='ActivityName'>{name}</div> 
                    {selectedDateStr && 
                        <RoomSlot>Salón 8</RoomSlot>
                    }</>
                }
                {name === 'LAC-IX' && <> 
                <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                    <div className='ActivityName'>{name}</div> 
                    {selectedDateStr && 
                        <RoomSlot>Salón 8</RoomSlot>
                    }
                    </>
                }
                
                {name === 'LACNIC' && <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>{name}</div> 
                    {selectedDateStr && 
                        <RoomSlot>Salón 4 - Plenaria</RoomSlot>
                    }
                </>}
                {name === 'LACNOG' && <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>{name}</div> 
                    {selectedDateStr && 
                        <RoomSlot>Salón 4 - Plenaria</RoomSlot>
                    }
                </>} 
                {name === 'Capture the Flag' && <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>{name}</div> 
                    {selectedDateStr && 
                        <RoomSlot>Ouro (planta baja)</RoomSlot>
                    }
                </>} 
            </div>

        )
    } 
}

export default ActivitySlot
 
