import React, { useContext,   useState } from 'react';
import { PostContext } from '../../../context/DataContext';
import Main from '../../../components/interface/main/Main';
import Footer from '../../../components/navegation/footer/Footer';
import Header from '../../../components/navegation/header/Header'; 
import UsePost from '../../../components/hook/UsePost';  
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import './travel-discounts.css'
import UseLangLocation from '../../../components/hook/UseLangLocation'; 
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';
import TravelAgencies from './TravelAgencies';

const TravelDiscounts = props => { 
    const { handleID, data, handleApiURL, handleLoading } = useContext(PostContext);
    const [loading, setLoading] = useState(true); 
   

    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/travel' handleLoading={handleLoading} handleID={handleID} id='2797' >
                <Header title={data?.title?.rendered} url_en='/en/hotels-and-travel/travel-discounts' url_pt_br='/pt-br/hoteis-e-viagem/descontos-em-passagens' url_es='/es/hoteles-y-viaje/descuentos-en-pasajes' loading={loading} {...props} />
                <Main status={data?.status} className='container travel_discounts' loading={loading}  >
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={data?.title?.rendered}> 
                            <SponsorsSlider/>
                            <StickyHeader/>
                            <TravelAgencies data={data}/> 
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default TravelDiscounts
